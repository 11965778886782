import {Fragment} from "react";
import Link from "next/link";
import {Company, LoanOffers, Lenders, Providers} from "../utils/footer";
import {uid} from "react-uid";
import {GetStarted} from "@components/ui/GetStarted";
import {useDispatch} from "react-redux";
import {TbWorld} from "react-icons/tb";
import {AppDispatch} from "state/store";
import {
  setFilter as setProviderFilter,
  clearFilters as clearProviderFilters,
} from "state/features/providersFilterSlice";
import countryData from "@utils/country.json";
import {
  setFilter as setLenderFilter,
  clearFilters as clearLenderFilters,
} from "state/features/lendersFilterSlice";
import Router, {useRouter} from "next/router";

interface IFooter {
  isLenders?: boolean;
}
const Footer = ({isLenders}: IFooter) => {
  const dispatch = useDispatch<AppDispatch>();

  const {query} = useRouter();

  const country = query?.country || "united-states";

  const activeCountryData = countryData.find(
    (c) => c.page_slug.toLowerCase() === (country as string).toLowerCase()
  ) || {
    country_code: "US",
    slug: "united-states",
    country_name: "United States of America",
    country_3code: "USA",
    continent_code: "NA",
    continent_name: "North America",
    page_slug: "united-states",
    currency_code: "USD",
    currency_name: "United States Dollar",
    phone_code: "1",
  };

  return (
    <Fragment>
      {/* <FAQ /> */}

      <GetStarted
        title="Get started with Lendsqr"
        description="Want to see first-hand how Lendqsr can push the boundaries of your lending business? Sign up now for free and check it out, we’ll be with you every step of the way!"
        buttonText="Create your free account"
        image="/assets/images/getstarted.svg"
        url="https://app.lendsqr.com/signup?utm_source=lendsqr-com-footer&utm_medium=web"
        negativeBottom
      />

      {!isLenders ? (
        <footer id="colophon">
          <div className="footer-top uni-width">
            {activeCountryData?.country_name && (
              <div className="footer-col">
                <a
                  href="/regions"
                  className="cursor-pointer"
                  target="_blank"
                  rel="nofollow"
                >
                  <span className="flex gap-2 ">
                    <TbWorld className="mb-[10px] w-full max-w-[20px]" />
                    <p className="footer-p ">
                      {activeCountryData?.country_name.split(",")[0]}{" "}
                      {/* {getLanguageName(activeCountryData?.language_code)}{" "} */}
                    </p>
                  </span>
                </a>
              </div>
            )}

            <div className="footer-col">
              <p className="footer-head">Solutions</p>

              <a
                href="/solutions/starter?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read about how to start lending with Lendsqr"
                rel="noreferrer"
              >
                <p className="footer-p">Start</p>
              </a>

              <a
                href="/solutions/scale?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read about using Lendsqr to scale your lending"
                rel="noreferrer"
                className="mb-32"
              >
                <p className="footer-p">Scale</p>
              </a>

              <a
                href="https://blog.lendsqr.com/how-lendsqr-helped-snapcash-bridge-the-credit-divide?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read about using Lendsqr to power your lending as a bank"
                rel="noreferrer"
              >
                <p className="footer-p">For Banks</p>
              </a>

              <a
                href="https://blog.lendsqr.com/coverdey-dey-for-you-empowering-underserved-traders-with-credit?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read about using Lendsqr to power your fintech lending"
                rel="noreferrer"
              >
                <p className="footer-p">For Fintechs</p>
              </a>

              <a
                href=""
                target="_blank"
                aria-label="Read about using Lendsqr to power your microfinance institution"
                rel="noreferrer"
              >
                <p className="footer-p">For Microfinance Institutions</p>
              </a>

              <a
                href="https://blog.lendsqr.com/234loan-is-transforming-lives-one-loan-at-a-time?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read about using Lendsqr to power your SME lending business"
                rel="noreferrer"
              >
                <p className="footer-p">For SMEs</p>
              </a>

              <a
                href="https://blog.lendsqr.com/what-are-payday-loans-and-how-to-make-the-best-of-them?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read about using Lendsqr to power your human resource strategy"
                rel="noreferrer"
              >
                <p className="footer-p">For Human Resources</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Products</p>
              <a
                href="/pricing?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read more about our features and pricing"
                rel="noreferrer"
              >
                <p className="footer-p">Pricing</p>
              </a>
              <a
                href="/how-to?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read more about how to use Lendsqr to power your lending"
                rel="noreferrer"
                className="mb-32"
              >
                <p className="footer-p">How to Use</p>
              </a>
              <a
                href="/products/channels?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read about using Lendsqr to power your loan channels"
                rel="noreferrer"
              >
                <p className="footer-p">Channels</p>
              </a>
              <a
                href="/products/back-office?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read about using Lendsqr to power your back office management"
                rel="noreferrer"
              >
                <p className="footer-p">Back Office</p>
              </a>
              <a
                href="/products/product-management?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read about using Lendsqr to power your loans product management"
                rel="noreferrer"
              >
                <p className="footer-p">Loan Product Management</p>
              </a>
              <a
                href="/products/savings?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read about using Lendsqr to power your savings and investment business"
                rel="noreferrer"
              >
                <p className="footer-p">Savings and Investments</p>
              </a>

              <a
                href="/products/payments?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Read more about our industries"
                rel="noreferrer"
              >
                <p className="footer-p">Payment and Collections</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Resources</p>
              <a
                href="https://blog.lendsqr.com/?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Blog</p>
              </a>

              <a
                href="https://lendsqr.freshdesk.com/support/home?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Read more about our channels"
                rel="noreferrer"
              >
                <p className="footer-p">Help Center</p>
              </a>
              <a
                href="https://adjutor.io/?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="View all our lenders"
                rel="noreferrer"
              >
                <p className="footer-p">Developers</p>
              </a>
              <a
                href="https://blog.lendsqr.com/category/lender-case-study/"
                target="_blank"
                aria-label="Read about case studies that show what Lendsqr have done for others"
                rel="noreferrer"
              >
                <p className="footer-p">Case Studies</p>
              </a>

              <a
                href="/lenders?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Discover providers whose services can scale your lending"
                rel="noreferrer"
              >
                <p className="footer-p">Discover providers</p>
              </a>

              <a
                href="/lenders?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Discover lenders and get the loans you need"
                rel="noreferrer"
              >
                <p className="footer-p">Discover lenders</p>
              </a>

              <a
                href="/lenders?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Discover loans to meet your immediate needs"
                rel="noreferrer"
              >
                <p className="footer-p">Discover loans</p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">Company</p>

              <a
                href="/about?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_self"
                aria-label="Contact our support"
                rel="noreferrer"
              >
                <p className="footer-p">About Lendsqr</p>
              </a>
              <a
                href="https://careers.lendsqr.com/jobs?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                aria-label="Sign up today"
                rel="noreferrer"
              >
                <p className="footer-p">Careers</p>
              </a>

              <a
                href="https://lendsqr.freshdesk.com/support/solutions/articles/44002506627-changelog?utm_source=lendsqr-com-footer&utm_medium=web"
                target="_blank"
                rel="noreferrer"
                aria-label="Read our privacy policy"
              >
                <p className="footer-p">Changelog</p>
              </a>
            </div>
          </div>

          <div className="footer-line uni-width"></div>

          <div className="footer-top uni-width">
            <div className="footer-col ">
              <p className="footer-head">Contact</p>

              <a
                href="mailto:support@lendsqr.com"
                target="_blank"
                aria-label="Go to origination"
                rel="noreferrer"
              >
                <p className="footer-p">support@lendsqr.com</p>
              </a>

              <div className="footer-media-holder">
                <a
                  target="_blank"
                  href="https://linkedin.com/company/lendsqr?utm_source=lendsqr-com-footer&utm_medium=web"
                  className="footer-anchor"
                  aria-label="Visit Lendsqr's page on LinkedIn"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/linkedin-footer.svg"
                    alt="linkedin icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://web.facebook.com/Lendsqr?utm_source=lendsqr-com-footer&utm_medium=web"
                  className="footer-anchor"
                  aria-label="Visit Lendsqr's page on Facebook"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/facebook-footer.svg"
                    alt="facebook icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/lendsqr?utm_source=lendsqr-com-footer&utm_medium=web"
                  className="footer-anchor"
                  aria-label="Visit Lendsqr's page on Instagram"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/instagram-footer.svg"
                    alt="instagram icon"
                  />
                </a>

                <a
                  target="_blank"
                  href="https://twitter.com/lendsqr?utm_source=lendsqr-com-footer&utm_medium=web"
                  className="footer-anchor"
                  aria-label="Visit Lendsqr's page on X (Twitter)"
                  rel="noreferrer"
                >
                  <img src="/assets/icons/x-footer.svg" alt="twitter icon" />
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/@Lendsqr?utm_source=lendsqr-com-footer&utm_medium=web"
                  className="footer-anchor"
                  aria-label="Visit Lendsqr's page on YouTube"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons/youtube-footer.svg"
                    alt="youtube icon"
                  />
                </a>
              </div>
            </div>

            <div className="footer-col">
              <p className="footer-head">United States</p>

              <a>
                <p className="footer-p">
                  2055 Limestone Rd Ste 200C, Wilmington, DE 19808 <br />
                </p>
              </a>
            </div>

            <div className="footer-col">
              <p className="footer-head">United Kingdom</p>

              <a>
                <p className="footer-p">
                  17 King Edwards Road, Ruislip, London, HA4 7AE
                </p>
              </a>
            </div>

            <div className="footer-col ">
              <p className="footer-head">Nigeria</p>

              <a>
                <p className="footer-p">
                  4 Perez Drive, Lekki Phase 1, Lagos 106104
                </p>
              </a>
            </div>

            <div className="footer-col ">
              <p className="footer-head">Caribbean</p>

              <a>
                <p className="footer-p">
                  C/O Si Caribbean Ltd., Level 2, Invaders Bay Tower, Invaders
                  Bay, Port of Spain, Trinidad W.I.
                </p>
              </a>
            </div>
          </div>

          <div className="footer-text uni-width">
            <div className="footer-p-text">
              <p>
                © {new Date().getFullYear()} Lendsqr, Inc. All rights reserved
              </p>

              <div>
                <a
                  href="/privacy"
                  className="mr-16 footer-p-anchor"
                  aria-label="Read about our privacy policy"
                >
                  Privacy Policy
                </a>

                <a
                  href="/terms"
                  className="footer-p-anchor"
                  aria-label="See our terms of condition"
                >
                  Terms of Use
                </a>
              </div>
            </div>

            <p>
              Lendsqr.com is NOT a lender, does not make offers for loans, and
              does not broker online loans to lenders or lending partners.
              Lendsqr.com is not a representative or agent of any lender or
              lending partner. Lendsqr.com is a lending-as-a-service cloud
              platform for licensed lenders. These disclosures are intended for
              informational purposes only and should not be construed as or
              considered legal advice.
            </p>
          </div>

          <img
            src="/assets/icons/footer-logo.svg"
            alt="footer logo"
            className="footer-logo"
          />
        </footer>
      ) : (
        <footer className="bg-blue-700 relative">
          <div className="">
            <div className="max-w-custom mx-auto w-[90%] lg:w-full">
              <div className="block lg:flex justify-between">
                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a loan
                  </h3>
                  <ul className="mt-6">
                    {LoanOffers.map((item) => (
                      <Fragment key={uid(item)}>
                        <li className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer">
                          <Link
                            href={
                              item.tenor && item.amount
                                ? `/lenders/loan-offers/${item.amount}-naira/${
                                    item.tenor
                                  }-month${
                                    item.tenor && Number(item.tenor) > 1
                                      ? "s"
                                      : ""
                                  }`
                                : "/offers"
                            }
                          >
                            {item.text}
                          </Link>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </div>
                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a lender
                  </h3>
                  <ul className="mt-6">
                    {Lenders.map((item) => (
                      <Fragment key={uid(item)}>
                        <li
                          className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();

                            if (item.type === null) {
                              dispatch(clearLenderFilters());
                              Router.push(item.url);
                            } else {
                              dispatch(
                                setLenderFilter({
                                  ["lenderType"]: [item.type],
                                  url: item.url,
                                })
                              );

                              Router.push(window.location.href);
                            }
                          }}
                        >
                          <Link
                            href={`${
                              item.type
                                ? `/lenders?lenderType=${item.type}`
                                : "lenders"
                            }`}
                          >
                            {item.text}
                          </Link>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </div>

                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">
                    Find a provider
                  </h3>
                  <ul className="mt-6">
                    {Providers.map((item) => (
                      <Fragment key={uid(item)}>
                        <li
                          className="body-sm-regular inter text-white-trans-7 mb-4 cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault();

                            if (item.type === null) {
                              dispatch(clearProviderFilters());
                              Router.push(item.url);
                            } else {
                              dispatch(
                                setProviderFilter({
                                  ["serviceType"]: [item.type],
                                  url: item.url,
                                })
                              );

                              Router.push(window.location.href);
                            }
                          }}
                        >
                          <Link
                            href={`${
                              item.type
                                ? `/providers?service_type=${item.type}`
                                : "providers"
                            }`}
                          >
                            {item.text}
                          </Link>
                        </li>
                      </Fragment>
                    ))}
                  </ul>

                  <hr className="border-200 border-white-600 my-7 opacity-20" />

                  <div className="footer-media-holder !flex-col gap-5">
                    <a
                      href="https://lsq.li/become-a-lender?link=https://app.lendsqr.com/signup&source=footer"
                      target="_blank"
                      aria-label="Read more about our channels"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <p className="text-white-trans-7 inter body-sm-regular">
                        I want to start lending
                      </p>
                    </a>
                    <a
                      href="/license"
                      target="_blank"
                      aria-label="Read more about our channels"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Do I need a license?
                      </p>
                    </a>
                  </div>
                </div>

                <div className="mt-[1.5rem] lg:mt-0">
                  <h3 className="body-sm-bold inter text-white-100">Lendsqr</h3>
                  <ul className="mt-6">
                    {Company.map((item) => (
                      <Fragment key={uid(item)}>
                        {item.isExternal ? (
                          <li className="body-sm-regular inter text-white-trans-7 mb-4">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.text}
                            </a>
                          </li>
                        ) : (
                          <li className="body-sm-regular inter text-white-trans-7 mb-4">
                            <Link href={item.url} passHref>
                              {item.text}
                            </Link>
                          </li>
                        )}
                      </Fragment>
                    ))}
                  </ul>
                  <hr className="border-200 border-white-600 my-7 opacity-20" />

                  <div className="footer-media-holder !flex-col gap-5">
                    <a
                      href="mailto:support@lendsqr.com"
                      target="_blank"
                      aria-label="Reach out to support email"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <img src="/assets/icons/email.svg" alt="email icon" />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        support@lendsqr.com
                      </p>
                    </a>
                    <a
                      href="tel:+2342017005280"
                      target="_blank"
                      aria-label="Reach out to support email"
                      className="footer-anchor flex-row gap-1 items-center"
                      rel="noreferrer"
                    >
                      <img src="/assets/icons/phone.svg" alt="email icon" />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        +234 201 700 5280
                      </p>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFuniv98WdGQAAAAYsiE3tAf9Plj1GdpTool61JNwhDaTg3TukePKrr5wM3aDQxMvN41pjeUGHcq-dvf53AqAakTduem_00NfHnjxHRPlMfl7vtxOqJXsRo0F0amREfej37Lx8=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Flendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our linkeding account"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/linkedin-footer.svg"
                        alt="linkedin icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        LinkedIn
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://web.facebook.com/Lendsqr?_rdc=1&_rdr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our facebook page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/facebook-footer.svg"
                        alt="facebook icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Facebook
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://www.instagram.com/lendsqr/"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our instagram page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/instagram-footer.svg"
                        alt="instagram icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Instagram
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://twitter.com/lendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our X (twitter) account"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/x-footer.svg"
                        alt="twitter icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        X (Twitter)
                      </p>
                    </a>

                    <a
                      target="_blank"
                      href="https://www.youtube.com/@Lendsqr"
                      className="footer-anchor flex-row gap-1 items-center"
                      aria-label="View our youtube page"
                      rel="noreferrer"
                    >
                      <img
                        src="/assets/icons/youtube-footer.svg"
                        alt="youtube icon"
                      />
                      <p className="text-white-trans-7 inter body-sm-regular">
                        Youtube
                      </p>
                    </a>
                  </div>
                </div>
              </div>

              <hr className="border-200 border-white-600 my-7 opacity-20" />
              <div className="flex flex-col md:flex-row md:justify-between w-full mt- gap-5">
                <p className="text-white-900 body-sm-regular inter">
                  © {new Date().getFullYear()} Lending Technologies. All rights reserved
                </p>
                <div className="flex flex-row gap-4 items-center">
                  <a
                    className="decoration-white-900 underline body-sm-regular inter text-white-900"
                    href={"/privacy"}
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>
                  <a
                    className="decoration-white-900 underline body-sm-regular inter text-white-900"
                    href={"/terms"}
                  >
                    {" "}
                    Terms of Use{" "}
                  </a>
                </div>
              </div>
              <div className="mt-10">
                <p className="text-white-900 body-sm-regular inter">
                  Lendsqr.com is NOT a lender, does not make offers for loans,
                  and does not broker online loans to lenders or lending
                  partners. Lendsqr.com is not a representative or agent of any
                  lender or lending partner. Lendsqr.com is a
                  lending-as-a-service or loan management software cloud
                  platform for licensed lenders. These disclosures are intended
                  for informational purposes only and should not be construed as
                  or considered legal advice.
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </Fragment>
  );
};

export {Footer};
