import React from "react";
import Lottie from "react-lottie";
import animationData from "@public/assets/lottie/loader.json";

const Loader = ({message}: {message: string}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col gap-3 justify-center items-center min-h-[500px] lg:min-h-[712px]">
      <div>
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <p className="py-5 text-center text-primary body-lg-bold inter">
        {message}
      </p>
    </div>
  );
};

export default Loader;
