import React from "react";
import Image from "next/image";
import Link from "next/link";

const Boundaries = () => {
  return (
    <div className="bg-green-700">
      <div className="masterd-inner mx-auto max-w-custom relative">
        <div className="h-[248px] w-full max-w-[750px] lg:w-[90%] md:h-[535px] absolute right-0 bottom-0">
          <Image
            src="/assets/icons/boundaries-vector.svg"
            layout="fill"
            alt="Lorem Ipsum"
            className="h-[100%] !-left-[5%] !-bottom-[20%] z-[50] lg:w-[90%] !mx-0"
          />
        </div>
        <div className="w-full min-h-[456px] lg:min-h-[320px] rounded-lg flex flex-col lg:flex-row justify-between lg:justify-normal">
          <div className="my-5 text-white p-6 lg:py-20 lg:px-0 max-w-[500px]">
            <h4 className="inter-display heading-4 mb-[30px] lg:mb-[15px] text-blue-700">
              Push the boundaries of your loan business!
            </h4>
            <p className="inter body-md-regular text-secondary">
              Book a custom demo today to see first-hand how Lendqsr can push
              the boundaries of your lending business.
            </p>
            <Link href="/contact-sales">
              <button className="mx-auto w-full mt-28 btn btn-primary body-md-bold lg:w-fit lg:mx-0">
                Book a free demo
              </button>
            </Link>
          </div>
          <div className="h-[248px] w-full md:h-[535px] self-end relative ml-auto right-0 lg:-right-[60px]">
            <Image
              src="/assets/images/boundaries.svg"
              layout="fill"
              alt="Lorem Ipsum"
              className="h-[100%] z-[50]"
            />
            {/* <div className="absolute top-5"> */}

            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boundaries;
