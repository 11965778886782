import {DownloadModalContext} from "@utils/contexts";
import {heroUnderList} from "@utils/data";
import {useContext} from "react";

type HeroType = {
  title: string;
  description?: string;
  heroImage?: string;
  newPost?: boolean;
  primaryText?: string;
  secondaryText?: string;
  primaryURL?: string;
  secondaryURL?: string;
  hideBottom?: boolean;
  hideSecondary?: boolean;
  backgroundColor?: string;
  noPadding?: boolean;
  isMini?: boolean;
  heroPosition?: string;
  heroTextPosition?: string;
  imgES?: string | number;
  heroES?: string | number;
};

const Hero = ({
  title,
  description,
  heroImage = "",
  newPost = false,
  primaryText,
  secondaryText,
  primaryURL,
  secondaryURL,
  hideBottom,
  hideSecondary,
  backgroundColor,
  noPadding,
  heroPosition,
  heroTextPosition,
  isMini,
  imgES,
  heroES,
}: HeroType) => {
  const {setShowDownloadModal} = useContext(DownloadModalContext);

  const openDownloadModal = () => {
    // Get the width of the screen
    const screenWidth = window.innerWidth;
    console.log(screenWidth);
    if (screenWidth >= 1024) {
      setShowDownloadModal && setShowDownloadModal(true);
    } else {
      window.open(process.env.NEXT_PUBLIC_GOOGLE_PLAYSTORE_URL, "_blank");
    }
  };

  return (
    <main
      className={`bg-white-200 ${noPadding ? "" : "hero-main"}`}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div
        // className={`uni-width hero-main-one ${
        //   !heroImage && "hero-no-image"
        // }`}
        className={`uni-width hero-main-one lg:gap-8 ${heroES}`}
        style={{
          height: isMini ? "400px" : "600px",
        }}
      >
        <div className="hero-one" style={{alignSelf: heroTextPosition}}>
          <div className="hero-one-inner">
            {newPost && (
              <div className="new-holder five-14 flex-center">
                <p className="new">NEW</p>
                <p
                  style={{marginRight: "7px", cursor: "pointer"}}
                  onClick={() => openDownloadModal()}
                >
                  Your back office is now on Play Store!{" "}
                </p>
                <img
                  src="/assets/icons/new-arrow.svg"
                  alt="new-arrow"
                  width={13.33}
                  height={10}
                />
              </div>
            )}

            <h1
              className="mt-20 text-brand-blue heading-3 inter-display"
              style={{color: backgroundColor ? "white" : ""}}
            >
              {title}
            </h1>

            <p
              className="mt-10 text-secondary body-lg-regular inter"
              style={{color: backgroundColor ? "white" : ""}}
            >
              {description}
            </p>

            <div className="hero-btn-holder flex gap-3 items-normal lg:items-center flex-wrap mt-32">
              {primaryText && (
                <a
                  className="mr-10 btn btn-primary body-md-bold min-w-[200px] min-h-[50px] w-full lg:w-fit text-center"
                  href={`${primaryURL ?? "https://app.lendsqr.com/signup"}`}
                  target={`${
                    !primaryURL?.includes("http") ? "_self" : "_blank"
                  }`}
                  rel="noreferrer"
                >
                  {primaryText}
                </a>
              )}
              {secondaryText && (
                <a
                  className="mr-10 btn btn-white body-md-bold min-w-[200px] min-h-[50px] w-full lg:w-fit text-center"
                  href={secondaryURL}
                  target={`${
                    !secondaryURL?.includes("http") ? "_self" : "_blank"
                  }`}
                  rel="noreferrer"
                >
                  {secondaryText}
                </a>
              )}
              {!hideSecondary && (
                <a
                  className="btn btn-secondary body-md-bold"
                  href="/contact-sales"
                  // target="_blank"
                  // rel="noreferrer"
                >
                  Contact Sales
                </a>
              )}
            </div>
          </div>
        </div>

        {heroImage && (
          <div className="hero-two" style={{alignSelf: heroPosition}}>
            <img alt="hero" className={`hero-image ${imgES}`} src={heroImage} />
          </div>
        )}
      </div>

      {!hideBottom && (
        <div className="hero-under">
          <div className="hero-under-inner uni-width flex-center">
            {heroUnderList.map((hero) => {
              return (
                <div key={hero.name} className="hero-under-cell  four-16">
                  <img
                    src="/assets/icons/check.svg"
                    alt="check svg"
                    className="mr-12"
                  />
                  <p className="four-16 text-tetiary">
                    <span className="text-primary body-md-bold">
                      {hero.name}
                    </span>{" "}
                    <span className="body-md-regular">{hero.description}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </main>
  );
};

export {Hero};
