import internal from "stream";

export const amountFilterDropdown = [
  {
    value: "Below 4,999",
    option: "Below 4,999",
  },
  {
    value: "5,000 - 49,999",
    option: "5,000 - 49,999",
  },
  {
    value: "50,000 - 199,999",
    option: "50,000 - 199,999",
  },
  {
    value: "200,000 - 499,999",
    option: "200,000 - 499,999",
  },
  {
    value: "500,000 - 999,999",
    option: "500,000 - 999,999",
  },
  {
    value: "1,000,000 - 4,999,999",
    option: "1,000,000 - 4,999,999",
  },
  {
    value: "Above 5,000,000",
    option: "Above 5,000,000",
  },
];

export const tenorFilterDropdown = [
  {
    value: "1 - 29 days",
    option: "Less than a month",
  },
  {
    value: "1 - 2 months",
    option: "1 - 2 months",
  },
  {
    value: "3 - 6 months",
    option: "3 - 6 months",
  },
  {
    value: "7 - 12 months",
    option: "7 - 12 months",
  },
];

export const lenderTypesDropdown = [
  {
    value: "powered by lendsqr",
    option: "powered by lendsqr",
  },
  {
    value: "other lender",
    option: "other lender",
  },
];

export const serviceTypeDropdown = [
  {
    value: "payments",
    option: "payments",
  },
  {
    value: "Verification",
    option: "Verification",
  },
  {
    value: "infrastructure",
    option: "infrastructure",
  },

  {
    value: "Data",
    option: "Data",
  },
  {
    value: "Credit Scoring",
    option: "Credit Scoring",
  },

  {
    value: "cards",
    option: "cards",
  },
  {
    value: "loan recovery",
    option: "loan recovery",
  },
];

export const countryDropdown = [
  {
    value: "nigeria",
    option: "Nigeria (₦)",
  },
  {
    value: "canada",
    option: "Canada ($)",
  },
  {
    value: "rwanda",
    option: "Rwanda (RWF)",
  },
  {
    value: "united kingdom",
    option: "United kingdom (£)",
  },
];

export const ratingDropdown = [
  {
    option: 1.0,
    value: 1.0,
  },
  {
    option: 2.0,
    value: 2.0,
  },
  {
    option: 3.0,
    value: 3.0,
  },
  {
    option: 4.0,
    value: 4.0,
  },
  {
    option: 5.0,
    value: 5.0,
  },
];

export const integrationsFilterDropdown = [
  "All",
  "Communication & Collaboration",
  "Cloud & Infrastructure",
  "Data Providers",
  "Finance & Payments",
];

export const requirementsDropdown = [
  {value: "BVN", option: "BVN"},
  {value: "Phone Number", option: "Phone Number"},
  {value: "Valid Debit Card", option: "Valid Debit Card"},
  {value: "Valid ID", option: "Valid ID"},
  {value: "Valid Bank Account", option: "Valid Bank Account"},
];

export const navlist = [
  {
    name: "Solutions",
    extra: true,
  },

  {
    name: "Products",
    extra: true,
  },

  {
    name: "Pricing",
    extra: true,
    url: "/pricing",
  },

  {
    name: "Resources",
    extra: true,
  },
];

export const heroUnderList = [
  {
    name: "Quick Integration.",
    description: "Set up your lending business with time-saving integration.",
  },

  {
    name: "Automated Processing.",
    description: "Real-time automation to optimize your business.",
  },

  {
    name: "Fully Customizable",
    description: "Choose from powerful features that work for your business.",
  },
];

export const capabilitiesList = [
  "Origination",
  "Decisioning",
  "Approval",
  "Disbursement",
  "Collections",
];

export const capabilitiesData = [
  {
    cap: "origination",
    title: "We’ve digitized the entire loan origination process",
    description:
      "Launch your lending business effortlessly across multiple channels including web, mobile, USSD, and APIs. Our lending software solutions get your loan business up and running in 5 minutes.",
    image: "/assets/images/origination.svg",
    data: [
      {
        title: "Channels & Distribution",

        description:
          "Create and manage diverse loan products that cater exactly to what your customers want, all with our easy-to-use management tools.",
        url: "/products/channels",
      },
      {
        title: "Loan Product Management",

        description:
          "Create and manage diverse loan products that cater exactly to what your customers want, all with our easy-to-use management tools.",
        url: "/products/product-management",
      },
      {
        title: "Savings & Investment Management",

        description:
          "Create and manage diverse loan products that cater exactly to what your customers want, all with our easy-to-use management tools.",
        url: "/products/savings",
      },
    ],
  },

  {
    cap: "decisioning",
    title:
      "Go beyond the surface to check the creditworthiness of your borrowers.",
    description:
      "Elevate your lending process with Oraculi, our sophisticated and customizable credit decisioning and scoring engine. Achieve unparalleled accuracy and A1 efficiency in your lending decisions with our loan management platform.",
    image: "/assets/images/decision.svg",
    data: [
      {
        title: "Karma",

        description:
          "Tap into one of the largest private blacklist database of bad actors and chronic defaulters in Nigeria.",
        url: "/products/decision-model",
      },
      {
        title: "Credit Bureau",

        description:
          "Get real time data online for anyone from any of Nigeria’s credit bureaus. You can configure one or multiple credit bureaus to be queried at the same time.",
        url: "/products/decision-model",
      },
      {
        title: "Ecosystem",

        description:
          "Tap into the massive ecosystem data of borrowers within Lendsqr to make informed decisions. This is similar to credit bureau data but on steroids.",
        url: "/products/decision-model",
      },
    ],
  },

  {
    cap: "approval",
    title:
      "Empower your loan approval process with our advanced lending software",
    description:
      "Choose from manual approvals, automated systems, or a combination of both to suit your operational needs and preferences. With our lending automation software, you can seamlessly manage all aspects of your operations.",
    image: "/assets/images/approval.svg",
    data: [
      {
        title: "Customer Management",

        description:
          "Take advantage for our all-in-one tool to manage all your customers efficiently and track the metrics that matter",
        url: "/products/back-office",
      },
      {
        title: "Automations",

        description:
          "Reduce the risk of manual errors with automations that supercharge your workflow in regards to accuracy and efficiency.",
        url: "/products/back-office",
      },
      {
        title: "Teams",

        description:
          "Simplify teamwork by inviting team members and assigning specific permissions for streamlined business management. ",
        url: "/products/back-office",
      },
    ],
  },

  {
    cap: "disbursement",
    title: "Control how your loans are disbursed.",
    description:
      "Accelerate your loan disbursement with our quick loan disbursement feature. Transfer funds to app wallets or bank accounts within seconds of approval, ensuring prompt access for your customers.",
    image: "/assets/images/disbursement.svg",
    data: [
      {
        title: "Transfer",

        description:
          "Customers can transfer money to their bank accounts. Transactions come with PDF receipts which can be shared with recipients and other stakeholders, where required.",
        url: "/products/payments",
      },
      {
        title: "Airtime",

        description:
          "Customers can top up their own phones or phones of others on any telco network.",
        url: "/products/payments",
      },
      {
        title: "Bills Payment",

        description:
          "Customers can effortlessly settle utilities and services directly through our platform, making disbursement even easier.",
        url: "/products/payments",
      },
    ],
  },

  {
    cap: "collections",
    title: "Offer your borrowers multiple payment options",
    description:
      "Empower your borrowers with various payment methods. Our loan management software ensures timely loan recovery by automatically collecting payments from your borrowers' accounts via direct debit on the due dates.",
    image: "/assets/images/collections.svg",
    data: [
      {
        title: "Card",

        description:
          "Customers can make payments with their debit cards and you can also determine the type of cards you would like to accept for payment.",
        url: "/products/payments",
      },
      {
        title: "USSD",

        description:
          "Customers can fund their wallet to repay their loans, or top up savings via USSD.",
        url: "/products/payments",
      },
      {
        title: "Direct Debit",

        description:
          "Automatically collect recurring payments from a customer’s bank account with their prior authorisation. Reduce the risk of late or missed payments and offer your customers a hassle-free experience.",
        url: "/products/payments",
      },
    ],
  },
];

export const pricingList = ["Monthly", "Yearly (save 20%)"];

export type PlanType = {
  title: string;
  description: string;
  nairaMonth: string;
  nairaYear: string;
  dollarMonth: string;
  dollarYear: string;
  priceDescription: string;
  featuresHeader: string;
  features: string[];

  supportFeatures: string[];
};

export const mainPricingDetails = [
  {
    title: "End-user-channels",
    show: true,
    fields: [
      {
        title: "Web app",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Loan invitations",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "External loans (Web SDK)",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Lendsqr Mobile Lite (Android only)",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "External loans",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "White label mobile app",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Custom mobile app",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Development services",
    show: false,
    fields: [
      {
        title: "App development",
        plan: {
          free: "uncheck",
          pro: "Contract basis",
          business: "Contract basis",
          enterprise: "Contract basis",
        },
      },
      {
        title:
          "Add-on features development and in-built customizations implementation",
        plan: {
          free: "uncheck",
          pro: "Contract basis",
          business: "Contract basis",
          enterprise: "Contract basis",
        },
      },
      {
        title: "Special integrations/customizations",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "Contract basis",
        },
      },
    ],
  },
  {
    title: "Back office",
    show: false,
    fields: [
      {
        title: "Users",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Whitelist",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "KYC document review",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Loans and Loan Products",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Loan invites",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Decision models",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Disbursements",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Transactions",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Services",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Settlement",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Audit Logs",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Notification settings",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Team",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Reports",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Organization documents",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Customer transaction fees",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Tier management",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Gurantors",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Web app platform settings",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Web app domain settings",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Offer letters",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Team roles and permissions",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Referral configurations",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "3rd party beneficiaries",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Savings and savings products",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Adjutor API service",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Value-added services",
    show: false,
    fields: [
      {
        title: "Direct Debit",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Bills and Airtime",
        plan: {
          free: "uncheck",
          pro: "Contract basis",
          business: "Contract basis",
          enterprise: "Contract basis",
        },
      },
      {
        title: "Interbank transfer",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Special integrations",
    show: false,
    fields: [
      {
        title: "3rd party integrations",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
      {
        title: "CBA/ERP integrations",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Platform services and pricing",
    show: false,
    fields: [
      {
        title: "Transaction processing (inflows)",
        plan: {
          free: "₦200 + 2% capped at ₦2,500",
          pro: "₦200 + 2% capped at ₦2,500",
          business: "₦200 + 2% capped at ₦2,500",
          enterprise: "₦200 + 2% capped at ₦2,500",
        },
      },
      {
        title: "Transaction processing (outflows)",
        plan: {
          free: "20",
          pro: "20",
          business: "20",
          enterprise: "20",
        },
      },
      {
        title: "Automated loan processing (Default)",
        plan: {
          free: "Free",
          pro: "Free",
          business: "Free",
          enterprise: "Free",
        },
      },
      {
        title: "Loan invitation email fee",
        plan: {
          free: "Free",
          pro: "Free",
          business: "Free",
          enterprise: "Free",
        },
      },
      {
        title: "Loan reminder email fee",
        plan: {
          free: "Free",
          pro: "Free",
          business: "Free",
          enterprise: "Free",
        },
      },
      {
        title: "General notification email fee",
        plan: {
          free: "Free",
          pro: "Free",
          business: "Free",
          enterprise: "Free",
        },
      },
      {
        title: "Loan invitation SMS fee",
        plan: {
          free: "₦5",
          pro: "₦5",
          business: "₦5",
          enterprise: "₦5",
        },
      },
      {
        title: "Loan reminder SMS fee",
        plan: {
          free: "₦5",
          pro: "₦5",
          business: "₦5",
          enterprise: "₦5",
        },
      },
      {
        title: "General SMS fee",
        plan: {
          free: "₦5",
          pro: "₦5",
          business: "₦5",
          enterprise: "₦5",
        },
      },
      {
        title: "User onboarding fee",
        plan: {
          free: "₦50 per user signed up",
          pro: "₦40 per user signed up",
          business: "₦35 per user signed up",
          enterprise: "₦30 per user signed up",
        },
      },
      {
        title: "Offline user creation fee",
        plan: {
          free: "₦40",
          pro: "₦35",
          business: "₦25",
          enterprise: "₦20",
        },
      },
      {
        title: "Offline loan booking fee",
        plan: {
          free: "₦50",
          pro: "₦45",
          business: "₦35",
          enterprise: "₦30",
        },
      },
      {
        title: "Credit Bureau check",
        plan: {
          free: "₦100",
          pro: "₦90",
          business: "₦90",
          enterprise: "₦90",
        },
      },
      {
        title: "SMS SDK bank account check",
        plan: {
          free: "uncheck",
          pro: "₦30",
          business: "₦20",
          enterprise: "₦10",
        },
      },
      {
        title: "SMS SDK bank statement check",
        plan: {
          free: "uncheck",
          pro: "₦45",
          business: "₦35",
          enterprise: "₦25",
        },
      },
      {
        title: "Fenestra bank statement check",
        plan: {
          free: "uncheck",
          pro: "₦65",
          business: "₦55",
          enterprise: "₦45",
        },
      },
      {
        title: "Bills payment service fee (VAS)",
        plan: {
          free: "uncheck",
          pro: "₦100",
          business: "₦100",
          enterprise: "₦100",
        },
      },
    ],
  },
  {
    title: "Platform support",
    show: false,
    fields: [
      {
        title: "Email support",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Chat support",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Phone support",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Lendsqr priority support",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Business support",
    show: false,
    fields: [
      {
        title: "Basic training",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Premium training",
        plan: {
          free: "check",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Priority access to features",
        plan: {
          free: "uncheck",
          pro: "check",
          business: "check",
          enterprise: "check",
        },
      },
      {
        title: "Performance reviews",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "check",
          enterprise: "check",
        },
      },
    ],
  },
  {
    title: "Technical advisory and support",
    show: false,
    fields: [
      {
        title: "Integration advisory",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
      {
        title: "Integration and 3rd party software support",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
      {
        title: "Dedicated product manager",
        plan: {
          free: "uncheck",
          pro: "uncheck",
          business: "uncheck",
          enterprise: "check",
        },
      },
    ],
  },
];

export const boxData = [
  {
    title: "Cost effective",
    description:
      "Launch at 5% of the cost of building your own proprietary platform.",
    cirlce_color: "box-info-hover",
    color: "box-info",
    image: "/assets/icons/cost-effect.svg",
  },
  {
    title: "Faster time to market",
    description:
      "Launch in weeks with our mobile app offering. Launch in hours with our web and USSD.",
    cirlce_color: "box-purple-hover",
    color: "box-purple",
    image: "/assets/icons/faster-time.svg",
  },
  {
    title: "Community immunity",
    description:
      "Access to tons of data from other lenders to collaboratively block bad borrowers.",
    cirlce_color: "box-teal-hover",
    color: "box-teal",
    image: "/assets/icons/community.svg",
  },
  {
    title: "Expertise",
    description:
      "Lendsqr talents have extensive experience with credit, data, and tech.",
    cirlce_color: "box-danger-hover",
    color: "box-danger",
    image: "/assets/icons/expertise.svg",
  },
];

export const unleashData = [
  {
    icon: "assets/icons/Layer_1.svg",
    cap: "BACK OFFICE",
    title: "All-in-one loan management tool empowered to drive business growth",
    description:
      "Manage all your resources on our robust back office, dedicated to provide you al you need to make the most of your operations. From dashboards, product management to reporting, you never miss a beat.",
    anchorText: "Learn more about our Back Office",
    url: "/products/back-office",
    internal: true,
  },
  {
    icon: "assets/icons/Fintech.svg",
    cap: "integrations",
    title: "Seamless multiple integrations ensuring you're always at 100%",
    description:
      "Lendsqr is the only powerhouse you need to drive your lending business. We have all our features integrated into everything you need to run smoothly.",
    anchorText: "Learn more about Integrations",
    url: "/integrations",
    internal: true,
  },
];

export const solutionsUnleash = [
  {
    icon: "/assets/images/starter-1.svg",
    cap: "resources to start",
    title: "Getting a license",
    description:
      "With Lendsqr, the complex world of licensing and compliance becomes straightforward. We guide you through every step required in obtaining either a state money lending license or a license from the Central Bank of Nigeria for you to get started.",
    anchorText: "Learn more",
    url: "mailto:support@lendsqr.com",
    internal: false,
  },
  {
    icon: "/assets/images/starter-2.svg",
    cap: "resources to start",
    title: "Getting the capital you need",
    description:
      "With Lendsqr you have access to a wider spectrum of Lendsqr ecosystem data for free. Beyond that, Lendsqr has partnered with on-lenders like CashDrive, to provide the necessary capital you need to start.",
    anchorText: "Learn more",
    url: "mailto:support@lendsqr.com",
    internal: false,
  },

  {
    icon: "/assets/images/starter-3.svg",
    cap: "BACK OFFICE",
    title: "Manage your lending operations seamlessly",
    description:
      "With your custom back office, handling your lending operations from start to finish becomes a breeze. We've designed it to give you complete control and clarity over every aspect of your business, ensuring smooth and efficient operations.",
    anchorText: "Learn more",
    url: "/products/back-office",
    internal: true,
  },
  {
    icon: "/assets/images/starter-4.svg",
    cap: "channels",
    title: "Showcase your business like a boss",
    description:
      "No website? No problem. Lendsqr provides you with a customized microsite and web app, making sure your customers have all the information they need about your loans and other services. ",
    anchorText: "Learn more",
    url: "/products/channels",
    internal: true,
  },
];

export type UnleashType = {
  icon: string;
  cap: string;
  title: string;
  description: string;
  anchorText: string;
  url: string;
  internal?: boolean;
};

export const lendersData = [
  {
    title: "5500+ lenders",
    description:
      "We're helping lenders across the world scale their loan business",
    number: "5500",
    color: "rgba(52, 152, 152, 1)",
  },
  {
    title: "2.1m+ customers",
    description:
      "Our Lending-as-a-Service platform helps lenders serve over 2.1m humans across the world.",
    number: "2.1m",
    color: "rgba(149, 55, 82, 1)",
  },
  {
    title: "Available in 100+ countries",
    description:
      "We're global but also localized for every currency, locale, and most languages.",
    number: "100",
    color: "rgba(94, 77, 178, 1)",
  },

  {
    title: "1/20 the cost",
    description:
      "Getting started with Lendsqr is practically free. Our lenders leverage a robust infrastructure at 1/20 the cost it would take to build theirs!",
    number: "1/20",
    color: "rgba(165, 72, 0, 1)",
  },
  {
    title: "30 mins average time to market",
    description:
      "It takes no less than 30 mins for a lender to sign up and start accepting loan requests. Yet, we also deliver customized features in record time.",
    number: "30 mins",
    color: "rgba(32, 106, 131, 1)",
  },
];

export const wordData = [
  {
    logo: "/assets/icons/kredi.svg",
    text: "The Lendsqr support team is truly exceptional, and have been instrumental to KrediBank's success",
    ownerIcon: "/assets/icons/afolabi.svg",
    name: "Afolabi Abimbola",
    role: "Founder/CEO, Kredi MFB",
    country: "/assets/icons/nigeria.svg",
  },
  {
    logo: "/assets/icons/paycient.svg",
    text: "With Lendsqr we were able to launch in 20% of the time and at 1% of the cost it would have taken us to build our stack from scratch.",
    ownerIcon: "/assets/icons/abayomi.svg",
    name: "Yomi Sule",
    role: "Founder/CEO, Paycient Finance",
    country: "/assets/icons/nigeria.svg",
  },
  {
    logo: "/assets/icons/standardlife.svg",
    text: "The Lendsqr team is always reliable and trustworthy, consistently putting the customer's best interests first.",
    ownerIcon: "/assets/icons/ayegue.svg",
    name: "Oyegue Osazee ",
    role: "Executive Director, Standard Life",
    country: "/assets/icons/rwanda.svg",
  },

  {
    logo: "/assets/icons/234.svg",
    text: "Lendsqr has provided the necessary support from our go-live phase and even till date. We owe a part of 234Loan's success to this consistent support.",
    ownerIcon: "/assets/icons/adewale.svg",
    name: "Adewale Otolorin",
    role: "CEO, 234Loan",
    country: "/assets/icons/nigeria.svg",
  },
  {
    logo: "/assets/icons/urgent10.svg",
    text: "We can't recommend Lendsqr enough. Their technology is simply the best when it comes to lending at scale!",
    ownerIcon: "/assets/icons/bankole.svg",
    name: "Bankole Akinwunmi",
    role: "Co-founder, Urgent10k",
    country: "/assets/icons/nigeria.svg",
  },

  {
    logo: "/assets/icons/coverdey.svg",
    text: "Because our customers are mostly artisans and not exactly tech savvy, the manual loan feature makes it easy for us to help out and save the day.",
    ownerIcon: "/assets/icons/damaris.svg",
    name: "Damaris Onyendi",
    role: "CEO, Coverdey",
    country: "/assets/icons/nigeria.svg",
  },
];

export const tailoredData = [
  {
    title: "Banks",
    description:
      "Expand digital lending, reaching the unbanked and amplifying market presence.",
    icon: "/assets/icons/banks.svg",
    color: "rgba(232, 247, 247, 1)",
    url: "https://blog.lendsqr.com/how-lendsqr-helped-snapcash-bridge-the-credit-divide/",
  },
  {
    title: "Fintechs",
    description:
      "Connect with our ecosystem of lenders to deliver your solutions at scale.",
    icon: "/assets/icons/fintech-card.svg",
    color: "rgba(255, 223, 163, 1)",
    url: "https://blog.lendsqr.com/coverdey-dey-for-you-empowering-underserved-traders-with-credit/",
  },
  {
    title: "SME Lenders",
    description:
      "Tailored digital lending solutions and expert support at a fraction of the cost.",
    icon: "/assets/icons/sme-lenders.svg",
    color: "rgba(235, 240, 250, 1)",
    url: "https://blog.lendsqr.com/234loan-is-transforming-lives-one-loan-at-a-time",
  },
  {
    title: "Microfinance Institutions",
    description:
      "Get a mobile app and an extensively configurable lending stack to meet your customers’ needs.",
    icon: "/assets/icons/microfinance-bank.svg",
    color: "rgba(255, 236, 235, 1)",
    url: "",
  },
  {
    title: "Human Resources",
    description:
      "Empower your employees with financial freedom with staff loans and provide the support they need to achieve their dream.",
    icon: "/assets/icons/hr.svg",
    color: "rgba(209, 237, 255, 1)",
    url: "https://blog.lendsqr.com/what-are-payday-loans-and-how-to-make-the-best-of-them/",
  },
];

export const solutionsScaleData = [
  {
    title: "Manage your lending operations like a pro",
    description:
      "Our Back Office provides you with all you need to grow and scale faster. Get access to the best of automations and extensive data on how your products and customers are doing.",
    checks: [
      "Faster loan processing and disbursement",
      "Automated repayments",
      "Track your most important customer metrics such as onboarding attempts, active and inactive users, and logins",
      "View and download settlement summary data",
      "Get insights and data into all transactions",
    ],
    anchorText: "Learn more about our Back Office",
    image: "/assets/images/products-backoffice-hero.svg",
    url: "/products/back-office",
    internal: true,
  },
  {
    title: "Go borderless with Lendsqr",
    description:
      "From mobile apps to USSD, Lendsqr opens up a world of possibilities, letting you connect with customers across multiple platforms.",
    checks: [
      "Web",
      "Mobile (Android and iOS)",
      "USSD",
      "Web SDK",
      "BNPL (Buy-Now-Pay-Later) SDK",
    ],
    anchorText: "Learn more about our Channels and Distributions",
    image: "/assets/images/products-channels-hero.svg",
    url: "/products/channels",
    internal: true,
  },
  {
    title: "Custom loans to fit your customers needs",
    description:
      "Create and manage diverse loan products that cater exactly to what your customers want, all with our easy-to-use management tools.",
    checks: [
      "Create and customize your loan products from single to multi-tenor loan options",
      "Manage loan fees and set your own interest rates",
      "Get additional information with configurable forms",
      "Configure how you want your customers to pay you",
      "Manual loan booking and third-party disbursements",
    ],
    anchorText: "Learn more about Loan Products Management",
    image: "/assets/images/products-products-hero.svg",
    url: "/products/product-management",
    internal: true,
  },
  {
    title: "Make smarter lending decisions with Lendsqr",
    description:
      "Our robust decision model includes integrations that we have with 3rd parties, aiding user prequalification and assessing their capacity to repay; ensuring you lend wisely and grow your business securely.",
    checks: [
      "Tap into the massive ecosystem data of borrowers within Lendsqr",
      "Get protection with Karma; our private blacklist database of bad actors and chronic defaulters",
      "Configure your scoring patterns",
      "Auto-analyzed bank data and statement checks for risk management",
      "Verify credit history via all 3 credit bureaus",
    ],
    anchorText: "Learn more about our Decision Models",
    image: "/assets/images/products-decisions-hero.svg",
    url: "/products/decision-model",
    internal: true,
  },
  {
    title: "Make your customers even happier with savings and investments",
    description:
      "Diversify your offerings and attract more with the ability to offer digital banking services to your customers by enabling savings and investments with multiple funding options.",
    checks: ["Recurring savings", "Fixed deposits", "Locked savings"],
    anchorText: "Learn more about Savings and Investments",
    image: "/assets/images/products-savings-hero.svg",
    url: "/products/savings",
    internal: true,
  },
  {
    title: "Payment and collections you can trust",
    description:
      "We offer robust integrations with organizations to ensure that loan payments, collections and settlement are done in a swift and seamless manner. Easily configure your payment rules across various channels for simplified lending operations..",
    checks: [
      "Accept payments with Cards",
      "Accept payments with USSD",
      "Accept payments with Direct Debit",
      "Accept payments with Transfers",
    ],
    anchorText: "Learn more about Payment and Collections",
    image: "/assets/images/products-payments-hero.svg",
    url: "/products/payments",
    internal: true,
  },
  {
    title: "Of course, we are with you every step of the way",
    description:
      "Get access to the best talent on everything lending. Our support is there to guide you through all you need to become even more successful as a lender.",
    checks: [
      "Email, Chat and Phone Support",
      "Lendsqr Priority Support",
      "Trainings",
      "Integration advisory",
      "Dedicated Product Manager",
    ],
    anchorText: "Get started today",
    image: "/assets/images/Visual.svg",
    url: "https://app.lendsqr.com/signup",
    internal: false,
  },
];

export const chanelsInfo = [
  {
    cap: "WEB",
    title: "Sign up to Lendsqr and get a web app in 5 minutes",
    description:
      "With Lendsqr you have access to a wider spectrum of Lendsqr ecosystem data for free. Beyond that, Lendsqr has partnered with on-lenders like CashDrive, to provide the necessary capital you need to start.",
    image: "/assets/images/channels-info-1.svg",
  },

  {
    cap: "USSD",
    title: "Reach even more customers directly with USSD integration",
    description:
      "Not every customer is internet savvy but with our USSD channel, lenders can reach over 3.5 billion users on our popular USSD channel.",
    image: "/assets/images/channels-info-2.svg",
  },

  {
    cap: "MOBILE",
    title: "Seamless mobile lending at your fingertips",
    description:
      "Start fast with our Mobile App framework in just a day, or choose a sophisticated white-label app ready in weeks. For a unique touch, you can collaborate with us on a custom mobile experience. ",
    image: "/assets/images/channels-info-3.svg",
  },

  {
    cap: "BNPL",
    title: "Offer more than just loans with Buy-Now-Pay-Later",
    description:
      "There is no better way to give out loans than to reach customers just when they need the money. Lendsqr supports lenders and e-commerce platforms that want to offer smart payment options with credit.",
    image: "/assets/images/channels-info-4.svg",
  },

  {
    cap: "WEB SDK",
    title: "Take your loans anywhere with embedded solutions",
    description:
      "With our web SDK, you get to seamlessly integrate loan applications into any website using a simple JavaScript SDK, allowing your borrowers to conveniently request and access your loans on any website of your choosing.",
    image: "/assets/images/channels-info-5.svg",
  },
];

export const productManagementInfo = [
  {
    cap: "loan products",
    title: "Craft loan products that fit your customers' unique needs",
    description:
      "Manage and customize your loans how you want and need; from ranging loan amounts to tenors. Disburse directly to customer's wallets, bank accounts or even third-parties.",
    image: "/assets/images/product-management-1.svg",
  },
  {
    cap: "manual loans",
    title: "Empower inclusive credit access through manual loan processing",
    description:
      "Our manual loan booking feature allows administrative handling of loans anytime. This feature caters to all customers, providing inclusive access to credit even to those not on online or mobile platforms.",
    image: "/assets/images/product-management-2.svg",
  },
  {
    cap: "guarantors",
    title: "Streamline guarantor requirements for added security",
    description:
      "Our system integrates a guarantor feature in your loan products for better security. You can set the number of guarantors per your risk criteria and the delay period before their involvement in default repayment.",
    image: "/assets/images/product-management-3.svg",
  },
  {
    cap: "CONFIGURABLE FORMS",
    title: "Enhance decision-making with customizable loan application forms",
    description:
      "Lendsqr's adaptable loan application forms empower lenders to make informed lending decisions.",
    image: "/assets/images/product-management-4.svg",
  },
  {
    cap: "interest and fees",
    title: "Manage interests and fees for all possible scenarios",
    description:
      "Tailor competitive and personalized interest rates for your borrowers. Choose between fixed or variable rates and also set a minimum interest amount offering you complete control over your lending terms to ensure profitability.",
    image: "/assets/images/product-management-5.svg",
  },
];

export const countryList = [
  {
    symbol: "NGN",
    flag: "/assets/icons/nigeria.svg",
  },
  {
    symbol: "USD",
    flag: "/assets/icons/usa.svg",
  },
];

export const defaultCountryCode = {
  ip: "8.8.8.8",
  city: "Mountain View",
  region: "California",
  region_code: "CA",
  country_code: "US",
  country_code_iso3: "USA",
  country_name: "United States",
  country_capital: "Washington",
  country_tld: ".us",
  continent_code: "NA",
  in_eu: false,
  postal: "94035",
  latitude: 37.386,
  longitude: -122.0838,
  timezone: "America/Los_Angeles",
  utc_offset: "-0800",
  country_calling_code: "+1",
  currency: "USD",
  currency_name: "Dollar",
  languages: "en-US,es-US,haw",
  asn: "AS15169",
  org: "Google LLC",
};

export const OffersSortingOptions = [
  "Amount - low to high",
  "Amount - high to low",
  "Most popular",
];

export const LendersSortingOptions = [
  "Relevance",
  "Amount - low to high",
  "Amount - high to low",
  "Approval time - low to high",
  "Approval time - high to low",
  "Most popular",
  "Newest",
];

export const smallPricingData = [
  {
    title: "Free",
    description: "For newbies who want to manage their personal lending better",
    nairaMonth: "0.00",
    nairaYear: "0.00",
    dollarMonth: "0.00",
    dollarYear: "0.00",
    priceDescription: "Free forever",
    featuresHeader: "Free features:",
    features: [
      "Loans and Loan Products",
      "Decision Models",
      "Disbursements",
      "Web App",
      "Loan invitations",
      "External Loans (Web SDK)",
    ],

    supportFeatures: ["Email Support", "Chat Support"],
  },
  {
    title: "Pro",
    description: "For growing businesses who want to leverage lending",
    nairaMonth: "200,000",
    nairaYear: "2,160,000",
    dollarMonth: "200.00",
    dollarYear: "2,160.00",
    priceDescription: "per month, billed monthly",
    featuresHeader: "All features in Starter +",
    features: [
      "Feature Customization",
      "Web App Domain Settings",
      "Teams",
      "Offer Letters",
      "Mobile App (Android Only)",
      "Direct Debit",
      "Bills and Airtime",
    ],

    supportFeatures: [
      "Lendsqr Priority Support",
      "Premium Training",
      "Priority Access to Features",
    ],
  },
  {
    title: "Business",
    description:
      "For established businesses who want to add lending to their stack",
    nairaMonth: "500,000",
    nairaYear: "5,400,000",
    dollarMonth: "500.00",
    dollarYear: "5,400.00",
    priceDescription: "per month, billed monthly",
    featuresHeader: "All features in Pro +",
    features: [
      "Referral Configurations",
      "3rd Party Beneficiaries",
      "Savings and Investment",
      "Adjutor API Service",
      "White Label Mobile App",
      "Interbank Transfer",
    ],

    supportFeatures: ["Performance Reviews"],
  },
  {
    title: "Enterprise",
    description:
      "For large organizations who need advanced lending infrastructure",
    nairaMonth: "1,000,000",
    nairaYear: "10,800,000",
    dollarMonth: "1,000.00",
    dollarYear: "10,800",
    priceDescription: "per month, billed monthly",
    featuresHeader: "All features in Business +",
    features: [
      "Custom Mobile App",
      "3rd Party Integrations",
      "CBA/ERP Integrations",
      "Web App",
      "Loan invitations",
      "External Loans (Web SDK)",
    ],

    supportFeatures: [
      "Integration Advisory",
      "3rd Party Software Support",
      "Dedicated Product Manager",
    ],
  },
];

export const smartDecisionsData = [
  {
    icon: "/assets/icons/decision-models-1.svg",
    free: true,
    title: "Karma",
    description:
      "Tap into one of the largest private blacklist database of bad actors and chronic defaulters in Nigeria. Avoid the most chronic defaulters before hand and reduce risk.",
  },
  {
    icon: "/assets/icons/decision-models-2.svg",
    free: true,
    title: "Whitelist",
    description:
      "Prequalify customers for your loans such that they can skip all the other checks, where required. Provide loans for customers who are pre-vetted and guaranteed to be good for the money.",
  },
  {
    icon: "/assets/icons/decision-models-3.svg",
    free: false,
    title: "Credit Bureau",
    description:
      "Get real time data online for anyone from any of Nigeria’s credit bureaus. You can configure one or multiple credit bureaus to be queried at the same time.",
  },
  {
    icon: "/assets/icons/decision-models-4.svg",
    free: true,
    title: "Ecosystem",
    description:
      "Tap into the massive ecosystem data of borrowers within Lendsqr to make a decision. This is similar to credit bureau data but on steroids.",
  },
  {
    icon: "/assets/icons/decision-models-5.svg",
    free: true,
    title: "Loci",
    description:
      "With the Loci model, you can write smart rules against high-speed velocity data of customers like checking instantly the number of times a borrower has made certain changes to their information.",
  },
  {
    icon: "/assets/icons/decision-models-6.svg",
    free: true,
    title: "Scoring",
    description:
      "Apply numeric weights and scores against data from customers, for example, setting higher scores for certain types of job employment.",
  },
  {
    icon: "/assets/icons/decision-models-7.svg",
    free: true,
    title: "Statement",
    description:
      "With our integration with MyBankStatement, Mono and Fenestra, lenders can make smart loan decisions using data obtained by consent via open APIs. We transform the raw information into transactional data from which loan insights can be garnered.",
  },
  {
    icon: "/assets/icons/decision-models-8.svg",
    free: true,
    title: "SMS Data",
    description:
      "This module is specific to Android devices. When its SDK is installed on an app, Lendsqr is able to read customers’ SMS messages and transform the raw SMS into transactional data to make smart loan decisions for you.",
  },
];

export const backOfficeInfo = [
  {
    cap: "customer MANAGEMENT",
    title: "The most efficient customer management tool for lenders",
    description:
      "Easily scale to manage your growing customer base. Track your most important customer metrics such as onboarding attempts, active and inactive users, and logins.",
    image: "/assets/images/back-office-1.svg",
  },
  {
    cap: "transaction monitoring",
    title: "Track real-time data on all the transactions in your business",
    description:
      "Gain a wider overview and summary of all the transactions that happen in your loan business. View and download all settlement summary data for faster resolutions and planning.",
    image: "/assets/images/back-office-2.svg",
  },
  {
    cap: "automations",
    title: "Reduce the risk of manual errors",
    description:
      "Our back office is fully equipped with powerful automations that reduce the time it takes to complete repetitive tasks like approvals, disbursement and repayments, allowing you to spend more time on growth.",
    image: "/assets/images/back-office-3.svg",
  },
  {
    cap: "teams",
    title: "Unleash efficient collaboration within your business",
    description:
      "Simplify teamwork by inviting team members and assigning specific permissions for streamlined business management. From risk assessment to client interactions, Lendsqr makes collaboration easy, enhancing your overall lending efficiency.",
    image: "/assets/images/back-office-4.svg",
  },
  {
    cap: "reporting",
    title: "Get detailed insights on your business",
    description:
      "Our dashboard and reports provide you with extensive data on how your products and customers are doing. Monitor your top performing products, customers and other metrics, helping you channel your efforts better.",
    image: "/assets/images/back-office-5.svg",
  },
];

export const valuesData = [
  {
    icon: "/assets/icons/value-1.svg",
    title: "Performance",
    description: "Operating at a level far above excellence",
  },
  {
    icon: "/assets/icons/value-2.svg",
    title: "Innovation",
    description: "Solving problems that stymie others",
  },
  {
    icon: "/assets/icons/value-3.svg",
    title: "Grit",
    description: "Never giving up",
  },
  {
    icon: "/assets/icons/value-4.svg",
    title: "Accountability",
    description: "Responsible. The buck stops here.",
  },
  {
    icon: "/assets/icons/value-5.svg",
    title: "Discipline",
    description: "Operating at a higher level of excellence than the best",
  },
];

export const moreAboutData = [
  {
    cap: "problem statement",
    title: "What problem is Lendsqr really solving?",
    description:
      "Learn more about the problem we are really trying to solving and the efforts we’ve made so far.",
  },
  {
    cap: "case studies",
    title: "How our Lenders use Lendsqr",
    description:
      "Read all about how our Lenders use Lendsqr and their success they’ve been able to achieve.",
  },
  {
    cap: "careers",
    title: "Become a Lendian!",
    description:
      "We’re all about making credit accessible to everyone. Join us and become a Lendian today!",
  },
];

export const privacyData = {
  title: "Privacy Policy",
  description:
    "This Privacy Policy (“Policy”) constitutes our commitment to your privacy on our platform. This Policy also forms a part of our holistic Terms of Use which you can access here.",
  headers: [
    {
      title: "About Lendsqr",
      description: [
        "Lendsqr provides a software platform for small and individual lenders to originate loans, score credits using credit bureau services or machine learning algorithms, collect and recover loans, and provide reports and analytics",
      ],
    },
    {
      title: "Your privacy rights",
      description: [
        "Lendsqr provides a software platform for small and individual lenders to originate loans, score credits using credit bureau services or machine learning algorithms, collect and recover loans, and provide reports and analytics",
        "If you are provided with a username, identification code, password, or any other piece of information as part of our security measures, you must treat such information as confidential, and you must not disclose it to any third party. We reserve the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these Conditions. If you know or suspect that anyone other than you knows your security details, you must promptly notify us at support@lendsqr.com.",
      ],
    },
  ],
};

export const navData = {
  solutions: {
    first: {
      title: "company",
      sections: [
        {
          title: "Start with Lendsqr",
          description: "Kickstart your lending journey with confidence",
          url: "/solutions/starter",
          internal: true,
        },
        {
          title: "Scale with Lendsqr",
          description: "The smartest lenders choose Lendsqr",
          url: "/solutions/scale",
          internal: true,
        },
        {
          title: "Build with Lendsqr",
          description: "The smartest lenders choose Lendsqr",
          url: "https://adjutor.io/",
          internal: true,
        },
      ],
    },

    second: {
      title: "Your Industry",
      sections: [
        {
          title: "For Banks",
          description: "",
          url: "https://blog.lendsqr.com/how-lendsqr-helped-snapcash-bridge-the-credit-divide/",
          internal: false,
        },
        {
          title: "For Fintechs",
          description: "",
          url: "https://blog.lendsqr.com/coverdey-dey-for-you-empowering-underserved-traders-with-credit/",
          internal: false,
        },
        {
          title: "For Microfinance Institutions",
          description: "",
          url: "",
          internal: false,
        },
        {
          title: "For SME Lenders",

          url: "https://blog.lendsqr.com/234loan-is-transforming-lives-one-loan-at-a-time/",
          internal: false,
        },
        {
          title: "For Human Resources",

          url: "https://blog.lendsqr.com/what-are-payday-loans-and-how-to-make-the-best-of-them/",
          internal: false,
        },
      ],
    },

    third: {
      title: "latest case study",
      image: "/assets/images/solutions-third.svg",
      subTitle:
        "How Lendsqr helped Sterling Bank use SnapCash to bridge the credit divide",
      url: "https://blog.lendsqr.com/how-lendsqr-helped-snapcash-bridge-the-credit-divide/",
      internal: false,
    },
  },

  products: {
    first: {
      title: "getting started",
      sections: [
        {
          title: "How to use",
          description: "Get started in 4 easy steps",
          image: "/assets/images/products-first.svg",
          url: "/how-to",
          internal: true,
        },
      ],
    },

    second: {
      title: "Products",
      sections: [
        {
          title: "Back Office",
          description: "",
          url: "/products/back-office?utm_source=menu",
          internal: true,
        },
        {
          title: "Channels",
          description: "",
          url: "/products/channels?utm_source=menu",
          internal: true,
        },
        {
          title: "Loan Product Management",
          description: "",
          url: "/products/product-management?utm_source=menu",
          internal: true,
        },
        {
          title: "Decision Models",
          description: "",
          url: "/products/decision-model?utm_source=menu",
          internal: true,
        },
        {
          title: "Savings and Investment",
          description: "",
          url: "/products/savings?utm_source=menu",
          internal: true,
        },
        {
          title: "Payments and Collections",
          description: "",
          url: "/products/payments?utm_source=menu",
          internal: true,
        },
      ],
    },

    third: {
      title: "Manage while Mobile",
      image: "/assets/images/backoffice_banner.svg",
      subTitle:
        "Download the Lendsqr Admin app from the Google Play Store. Your Lendsqr, more accessible than ever!",
      internal: false,
      url: "",
    },
  },

  resources: {
    title: "company",

    first: {
      title: "Learn",
      sections: [
        {
          title: "Blog",
          description: "Expertly crafted insights on everything about lending.",
          url: "https://blog.lendsqr.com/?utm_source=lendsqr-menu",
          internal: false,
        },
        {
          title: "Guides",
          description: "Your every questions answered about our products",
          internal: false,
          url: "https://lendsqr.freshdesk.com/support/home?utm_source=lendsqr-menu",
        },
        {
          title: "Case Studies",
          description: "Learn how we've helped our customers grow",
          internal: false,
          url: "https://blog.lendsqr.com/category/lender-case-study?utm_source=lendsqr-menu",
        },
        {
          title: "Videos",
          description: "Walkthroughs and insights",
          internal: false,
          url: "https://www.youtube.com/@Lendsqr?utm_source=lendsqr-menu",
        },
      ],
    },

    second: {
      title: "Discover",
      sections: [
        {
          title: "Providers",
          description:
            "Find providers to power your digital lending from payments, KYC to credit scoring and more.",
          url: "https://lendsqr.com/providers?utm_source=menu",
          internal: true,
        },
        {
          title: "Lenders",
          description:
            "Discover our lenders and every other lender all over the world helping to provide loans just when you need it.",
          url: "https://lendsqr.com/lenders?utm_source=menu",
          internal: true,
        },
        {
          title: "Loans",
          description:
            "We don’t provide loans directly, but we've curated a global list of options from our trusted lending partners.",
          url: "https://lendsqr.com/loans?utm_source=menu",
          internal: true,
        },
      ],
    },

    third: {
      title: "Build",
      image: "/assets/images/resources-third.svg",
      subTitle: "Powerful APIs to drive your lending and financial services.",
      internal: false,
      url: "https://adjutor.io/?source=lendsqr-menu",
    },
  },
};

const fetchDataFromStrapi = async (url: string) => {
  const apiKey = process.env.NEXT_PUBLIC_STRAPI_API_KEY;
  const apiUrl = process.env.NEXT_PUBLIC_STRAPI_BASE_URL;
  try {
    if (!apiKey || !apiUrl) {
      throw new Error("STRAPI_API_KEY or STRAPI_URL not found in environment");
    }

    const response = await fetch(`${apiUrl}${url}`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response}`);
    }

    const strapiContent = (await response.json()) as any;

    return {strapiContent};
  } catch (error) {
    console.error("Failed to fetch data:", error);
    return {
      error: error instanceof Error ? error.message : String(error),
    };
  }
};

export const getStrapiCountries = async () => {
  const data = await fetchDataFromStrapi("/countries");

  if (data.error || !data.strapiContent) return;

  return data.strapiContent.data?.map((country: any) => ({
    option: country.attributes.country_code,
    value: country.attributes.country_code,
    langCode: country.attributes.language_code,
  }));
};
