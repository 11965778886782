import {MainLayout} from "@layouts/MainLayout";
import {Hero} from "../home/hero";
import {TrustUs} from "../home/TrustUs";
import {Capabilities} from "../home/Capabilites";
import {BackgroundLines} from "../home/BackgroundLines";
import {LatestBlog} from "../lenders/LatestBlog";
import {Tailored} from "../home/Tailored";
import {Word} from "../home/Word";
import {LendersLove} from "../home/LendersLove";
import {Masterd} from "../home/Masterd";
import {Adjutor} from "../home/Adjutor";
import {Unleash} from "../home/Unleash";
import {unleashData} from "@utils/data";

const RegionComponent = ({region}: any) => {
  const pageElements = region?.page_elements?.split(",") || [];

  const showSection = (section: string) =>
    pageElements.includes(section) || !region?.page_elements;

  return (
    <MainLayout
      title={`Loan Management Software for the Smartest Lenders, Banks, and Fintechs in ${region.name}`}
      description={`Explore Lendsqr's loan management software for easy and efficient lending in ${region.name}. Streamline your lending operations with our end-to-end solutions.`}
      canonicalURL={`https://lendsqr.com/${region.slug}`}
      isHome
    >
      {showSection("hero") && (
        <Hero
          title="The best loan management system for smart lenders"
          description="Lending is profitable but also difficult but not with Lendsqr's loan management software! Start, launch and scale your loan business easily across multiple channels and make smart decisions all at a fraction of the cost!"
          heroImage="assets/images/HeroIllustration.svg"
          primaryText="Get started free "
          newPost={true}
          heroPosition="flex-end"
        />
      )}
      {showSection("trustus") && <TrustUs />}

      {showSection("capabilities") && <Capabilities />}

      {showSection("background") && <BackgroundLines />}

      {showSection("masterd") && <Masterd />}

      {showSection("adjutor") && <Adjutor />}

      {showSection("unleash") && (
        <Unleash
          unleashData={unleashData}
          title="Empower your loan business with Lendsqr"
          capText="everything you need to keep going"
          description="Lendsqr is the only powerhouse you need to drive your loan business. Our end-to-end digital lending software integrates all the features you need to run your loan operations smoothly."
        />
      )}

      {showSection("testimonial") && <LendersLove />}

      {showSection("word") && <Word />}

      {showSection("tailored") && <Tailored />}

      {showSection("blog") && <LatestBlog />}
    </MainLayout>
  );
};

export default RegionComponent;
