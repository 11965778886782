type InfoDataType = {
  cap?: string
  title: string
  description: string
  image: string
}

type ImageInfoProp = {
  data1: Array<InfoDataType>
  data2: Array<InfoDataType>
  data3?: InfoDataType
  title: string
  description: string
}

const ImageInfo = ({
  data1,
  data2,
  data3,
  title,
  description,
}: ImageInfoProp) => {
  return (
    <div className="image-info-holder padding-120">
      <div className="image-info-inner uni-width">
        <p className="text-brand-blue header-width heading-4 inter-display">
          {title}
        </p>
        <p className="text-secondary mb-64 header-width mt-20 body-lg-regular">
          {description}
        </p>

        <div className="image-info-info-holder">
          <div className="image-info-col">
            {data1.map((data) => {
              return <ColumnCell key={data.title} {...data} />;
            })}
          </div>

          <div className="image-info-col">
            {data2.map((data) => {
              return <ColumnCell key={data.title} {...data} />;
            })}
          </div>
        </div>

        {data3 && (
          <div key={data3.title} className="bottom-info-cell mt-24 card-shadow">
            <div className="image-info-info bottom-info-one mr-64">
              <p className="unleash-cap six-12 "> {data3.cap} </p>
              <p className="text-primay mt-12 image-info-header heading-6 inter-display">
                {data3.title}
              </p>
              <p className="text-secondary mt-16 image-info-header body-md-regular">
                {data3.description}
              </p>
            </div>

            <div className="overflow-hidden bottom-info-two">
              <img
                src={data3.image}
                className="web-info-image"
                alt="web-info-image"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ColumnCell = ({title, cap, description, image}: InfoDataType) => {
  return (
    <div key={title} className="image-info-cell column-cell card-shadow">
      <div className="image-info-info">
        <p className="unleash-cap six-12 "> {cap} </p>
        <p className="text-primary mt-12 image-info-header heading-6 inter-display">
          {title}
        </p>
        <p className="text-secondary mt-16 image-info-header body-md-regular">
          {description}
        </p>
      </div>

      <div className="overflow-hidden image-info-bottom">
        <img src={image} className="web-info-image" alt="web-info-image" />
      </div>
    </div>
  );
};

export {ImageInfo};
