const BestProviders = ({country}: {country: string}) => {
  return (
    <div className="providers__wrapper">
      <section>
        <div>
          <h2 className="heading-4 inter-display">
            Get providers to power your lending in {country}
          </h2>
          <p className="body-lg-regular text-[#ffffffde]">
            Need the right providers to support your lending? We’ve gathered
            everything from payment solutions to KYC tools, credit checks, and
            bank statement services all in one place. Let’s help you get started
            with the right partners today.
          </p>
          <a
            style={{width: "fit-content", marginTop: "16px"}}
            className="btn btn-primary mr-10 body-md-bold"
            href="https://lendsqr.com/providers"
            target="_blank"
            rel="noreferrer"
          >
            Explore now
          </a>
        </div>
        <img
          src="/assets/images/provider-illus.svg"
          alt="provider"
          width={600}
          height={421}
          loading="lazy"
        />
      </section>
    </div>
  );
};

export default BestProviders;
