import {Lender} from "state/features/allLendersSlice";
import {Provider} from "state/features/allProvidersSlice";
import {Offer} from "state/features/offersSlice";
import {Tooltip} from "state/features/tooltipSlice";

type CommonType = Offer | Lender | Provider;

export const formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

export function formatCurrency(
  amount: number,
  currency: string,
  locale = "en-US"
): string {
  // console.log("currency", currency, locale, typeof currency);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency || "NGN",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

export const getTextByCategory = (
  slug: string,
  data: Tooltip[]
): string | undefined => {
  let tooltips: Tooltip[] = data; // Initialize tooltips with data

  // Check if running in the browser
  if (typeof window !== "undefined") {
    const storedData = sessionStorage.getItem("tooltip-data");
    if (storedData) {
      try {
        tooltips = JSON.parse(storedData);
      } catch (error) {
        console.error("Error parsing storedData:", error);
        // tooltips will remain as data if parsing fails
      }
    }
  }

  if (tooltips) {
    for (const obj of tooltips) {
      if (obj.slug === slug) {
        return obj.text;
      }
    }
  }

  return undefined; // Return undefined if no matching category is found
};

export const updateCorrelation = (
  current: number[],
  newValues: number[]
): number[] => {
  return Array.from(new Set([...newValues])); // Remove duplicates
};

const getMax = (array: any, property: string): number => {
  return Math.max(
    ...array.map(
      (item: {id: number; attributes: CommonType}) =>
        (item.attributes as any)[property]
    )
  );
};

const countSort = (
  array: {id: number; attributes: CommonType}[],
  property: string,
  exp: number
): {id: number; attributes: CommonType}[] => {
  const output = new Array(array.length);
  const count = new Array(10).fill(0);

  // Count the occurrences
  for (let i = 0; i < array.length; i++) {
    const value = Math.floor((array[i].attributes as any)[property] / exp) % 10;
    count[value]++;
  }

  // Change count[i] so that count[i] now contains the actual
  // position of this digit in the output array
  for (let i = 1; i < 10; i++) {
    count[i] += count[i - 1];
  }

  // Build the output array
  for (let i = array.length - 1; i >= 0; i--) {
    const value = Math.floor((array[i].attributes as any)[property] / exp) % 10;
    output[count[value] - 1] = array[i];
    count[value]--;
  }

  // Copy the output array to array
  for (let i = 0; i < array.length; i++) {
    array[i] = output[i];
  }

  return array;
};

export const radixSort = (
  array: {id: number; attributes: CommonType}[],
  property: string,
  ascending: boolean
): {id: number; attributes: CommonType}[] => {
  const max = getMax(array, property);

  // Do counting sort for every digit
  for (let exp = 1; Math.floor(max / exp) > 0; exp *= 10) {
    array = countSort(array, property, exp);
  }

  // If descending order is needed, reverse the array
  if (!ascending) {
    array.reverse();
  }

  return array;
};

export function removeWhitespace(text: string): string {
  return text.replace(/\s+/g, "");
}

export function capitalizeWords(string: string) {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function parseString(input: string) {
  const amountMatch = input.match(/₦([\d,]+)/);
  const tenorMatch = input.match(/for (\d+) month/);

  const amount = amountMatch
    ? parseInt(amountMatch[1].replace(/,/g, ""))
    : null;
  const tenor = tenorMatch ? parseInt(tenorMatch[1]) : null;

  return {
    amount: amount || 500,
    tenor: tenor || 1,
  };
}

export const getFirstLettersFromTwoWords = (str: string) => {
  return str
    .split(" ")
    .slice(0, 2) // Take only the first two words
    .map((word) => word.charAt(0)) // Extract the first letter of each word
    .join(""); // Join the letters together
};

export const areAllArraysEmpty = (obj: any) => {
  return Object.values(obj)
    .filter(Array.isArray) // Only keep the properties that are arrays
    .every((array) => array.length === 0); // Check if each array is empty
};
