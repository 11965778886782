import KeywordCountry from "@components/templates/keyword-country/KeywordCountry";
import {MainLayout} from "@layouts/MainLayout";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "state/store";
import countryData from "@utils/country.json";
import {fetchDataFromStrapi} from "@utils/fetchDataFromStrapi";
import RegionComponent from "@components/templates/region/RegionComponent";
import {GetServerSidePropsContext} from "next";
import {serialize} from "cookie";

export const getServerSideProps = async ({
  params,
  res,
}: GetServerSidePropsContext) => {
  const {country} = params as {country: string};

  const countryExists = countryData.find(
    (c) =>
      c.country_name.toLowerCase() === country.toLowerCase() ||
      c.page_slug.toLowerCase() === country.toLowerCase()
  );

  console.log(countryExists, "countryExists");

  if (countryExists) {
    const strapiData = await fetchDataFromStrapi(
      `/countries?filters[slug][$eq]=${countryExists?.page_slug}`
    );

    if (strapiData.strapiContent?.data.length < 1 || strapiData.error) {
      return {
        redirect: {
          destination: "/?redirect=false",
          permanent: false,
        },
      };
    }

    return {
      props: {
        country: strapiData.strapiContent?.data[0]?.attributes || {},
      },
    };
  }
  return {
    props: {},
  };
};

const KeywordCountrySEO = (props: any) => {
  console.log(props);

  const {country, keyword} = useSelector(
    (state: RootState) => state.keywordCountry
  );

  if (props?.country) {
    return <RegionComponent region={props.country} />;
  }

  return (
    <MainLayout
      title={`Lendsqr | The best ${keyword} in ${country}`}
      description={`Lending is profitable but also difficult but not with Lendsqr’s ${keyword}! Start, launch and scale your loan business easily across multiple channels and make smart decisions all at a fraction of the cost!`}
      canonicalURL="https://lendsqr.com/loan-management-software-uk"
      isHome
    >
      <KeywordCountry />
    </MainLayout>
  );
};

export default KeywordCountrySEO;
